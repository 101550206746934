<template>
  <div id="ProfileNewsFeedWrapper">
    <div class="ProfileNewsFeed">
      <p class="NewsFeedTitle">Новости</p>
      <div class="NewsList">
        <NewsList class="NewsRestyle" />
      </div>
      <router-link class="NewsFooterText" to="/beta/news-list"
        >Все новости</router-link
      >
    </div>
  </div>
</template>

<style scoped>
#ProfileNewsFeedWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  padding: 28px 18px 28px 32px;

  overflow-y: auto;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: var(--o-s-global-border-radius);
}
#ProfileNewsFeedWrapper > .ProfileNewsFeed {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.ProfileNewsFeed > .NewsFeedTitle {
  position: relative;
  margin-bottom: 16px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* students/white */
  color: #e8e7ec;
}
.ProfileNewsFeed > .NewsList {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding-right: 14px;

  overflow-y: scroll;
}
.ProfileNewsFeed > .NewsFooterText {
  position: relative;
  width: auto;

  margin: 16px auto 0% 0%;
  padding-bottom: 1px;

  border-bottom: 2px solid rgba(232, 231, 236, 0.15);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* students/white */
  color: #e8e7ec;
}

.NewsBlock {
  position: relative;
  width: 100%;
  height: auto;
}

.NewsBlock > .NewsBlockText,
.Zaglushka {
  margin-bottom: 32px;
  height: fit-content;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  color: #e8e7ec;
}
.NewsBlock > .NewsBlockDate {
  margin-bottom: 16px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white 2 */
  color: #c8c3de;
}

.NewsList > .NewsBlock:not(:nth-child(1)) {
  margin-top: 16px;
}
.NewsList > .NewsBlock:not(:nth-last-child(1)) {
  border-bottom: 1px solid rgba(232, 231, 236, 0.2);
}
</style>

<style scoped>
.NewsRestyle:deep(.NB-Header) {
  display: none;
}
.NewsRestyle:deep(.NB-Desc) {
  -webkit-line-clamp: 4;
}
.NewsRestyle:deep(.News-Block) {
  gap: 8px;
}
</style>

<script>
import NewsList from "@/components/Profile/NewsList.vue";

export default {
  data() {
    return {};
  },
  name: "NewsFeed",
  components: { NewsList },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
