<template>
  <div
    id="ProfilePage"
    ref="ProfilePage"
    :class="education_show == true ? 'hidePageScroll' : ''"
  >
    <template v-if="!load">
      <template v-if="!userIsWorker || sprintJobIsEnded">
        <Sidebar />
        <div id="PageStart" ref="PageStart" class="ProfileContent">
          <div class="ProfileBlocks">
            <div class="profileBlock BlockColumn1">
              <ProfileProgressCard />
              <NewsFeed />
            </div>
            <div
              class="profileBlock BlockColumn2"
              :key="
                'block2' +
                  enoughCompetenceForJob +
                  PinnedTask?.UserPinTask?.[0]?.id +
                  PinnedTask?.UserPinTask?.[0]?.name
              "
            >
              <TipBlock
                v-if="enoughCompetenceForJob"
                :content="tipContent"
                :key="
                  'tipBlock' +
                    enoughCompetenceForJob +
                    isNewReqStatus +
                    tipContent +
                    sprintJobIsEnded
                "
              />

              <OctiJobRecs
                v-if="showOctiRecs"
                :section="
                  getFirstSection(PinnedTask?.UserPinTask?.[0]?.sections)
                "
                :pinnedTaskID="PinnedTask?.UserPinTask?.[0]?.id"
                :updatePage="initialization"
                :key="
                  'OctiJobRecs' +
                    PinnedTask?.UserPinTask?.[0]?.sectionId +
                    PinnedTask?.UserPinTask?.[0]?.id +
                    PinnedTask?.UserPinTask?.[0]?.section?.name
                "
              />

              <ProfileProjectCard
                v-else
                id="ProfileProjectCard"
                ref="ProfileProjectCard"
                :class="
                  education_show_component == 'ProfileProjectCard'
                    ? 'ShowOnEducation'
                    : ''
                "
                :Project="PinnedTask?.UserPinTask?.[0]"
                :userHasJobRequest="this.userHasJobRequest"
                :enoughCompetenceForJob="enoughCompetenceForJob"
                :isFreeJob="isFreeJob"
                :isRepeateable="jobIsRepeateable"
                :jobRequestSended="jobRequestSended"
                :GuestMode="false"
                :UseAutoWidth="true"
                :updatePage="initialization"
                :userIsWorker="userIsWorker"
                :isArchieveJob="isArchieveJob"
                :lastSprintDates="{
                  start: sprintsHistory?.[0]?.sprint_start,
                  end: sprintsHistory?.[0]?.sprint_end,
                }"
                :UserLevels="userLevels"
                :key="
                  'projectCardKey' +
                    enoughCompetenceForJob +
                    PinnedTask +
                    PinnedTask?.UserPinTask?.[0] +
                    PinnedTask?.UserPinTask?.[0]?.name +
                    userIsWorker +
                    sprintsHistory?.[0]?.sprint_start +
                    sprintsHistory?.[0]?.sprint_end
                "
              />

              <Competence
                id="ProfileCompetenceBlock"
                ref="ProfileCompetenceBlock"
                :class="
                  education_show_component == 'ProfileCompetenceBlock'
                    ? 'ShowOnEducation'
                    : ''
                "
                :header="true"
                :userLevels="userLevels"
                :competitionTip="true"
                :useWhiteListRequireBlock="true"
                :whiteListRequireBlock="
                  getSectionsArray(PinnedTask?.UserPinTask?.[0].levels)
                "
                :useAlternateMethod="true"
                :competenceWhiteList="
                  getSectionsArray(PinnedTask?.UserPinTask?.[0].sections)
                "
                :key="'CompetenceBlock' + getTaskCompBlockReq"
              />
            </div>
            <div class="profileBlock BlockColumn3">
              <TasksList
                v-if="
                  (!jobRequestSended &&
                    jobRequestDeclined.res == false &&
                    !showOctiRecs) ||
                    (!jobRequestSended &&
                      jobRequestDeclined.res == true &&
                      jobRequestDeclined.reason == 'user' &&
                      !isNewReqStatus &&
                      !sprintJobIsEnded &&
                      (jobIsRepeateable || isFreeJob))
                "
                :defaultStages="stagesList"
                :defaultSteps="stepsList"
                :userSteps="[]"
                :key="
                  'taskslist' +
                    jobRequestSended +
                    jobRequestDeclined.res +
                    showOctiRecs
                "
                OLD_userSteps="userStepsReady"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <TwoColumnsTemplate>
          <template v-slot:ColumnTwo>
            <div class="SprintContentWrapper">
              <ProfileProjectCard
                style="margin-bottom: 0px;"
                :Project="PinnedTask?.UserPinTask?.[0]"
                :userHasJobRequest="this.userHasJobRequest"
                :enoughCompetenceForJob="enoughCompetenceForJob"
                :isFreeJob="isFreeJob"
                :isRepeateable="jobIsRepeateable"
                :jobRequestSended="jobRequestSended"
                :GuestMode="false"
                :UseAutoWidth="true"
                :updatePage="initialization"
                :userIsWorker="userIsWorker"
                :lastSprintDates="{
                  start: sprintsHistory?.[0]?.sprint_start,
                  end: sprintsHistory?.[0]?.sprint_end,
                }"
                :userWorkOnSprint="true"
                :key="
                  'ProjectCardBlock2' +
                    enoughCompetenceForJob +
                    PinnedTask?.UserPinTask?.[0] +
                    PinnedTask?.UserPinTask?.[0]?.name +
                    userIsWorker +
                    sprintsHistory?.[0]?.sprint_start +
                    sprintsHistory?.[0]?.sprint_end
                "
                :UserLevels="userLevels"
              />

              <div class="SprintsContent" v-if="sprintDetail == null">
                <span class="sprints-header">Спринты</span>
                <div class="sprints-list">
                  <div
                    class="sprint-block"
                    v-for="(sprint, sindx) in sprintsHistory"
                    :key="`${sindx}${sprint.id}`"
                  >
                    <span class="sprint-block-title"
                      >Спринт {{ sprintsHistory.length - sindx }} от
                      {{ getHumanDate(sprint.sprint_start) }}
                      <span class="sprint-block-title-bold" v-if="sindx == 0"
                        >до {{ getHumanDateWithTime(sprint.sprint_end) }}</span
                      >
                    </span>

                    <div class="sprint-block-card">
                      <span
                        class="sprint-card-status"
                        :style="
                          sprintStarted(sprint.sprint_start)
                            ? !sprintStarted(sprint.sprint_end)
                              ? ''
                              : 'color: #ED254E; border-color: #ED254E;'
                            : `color: #f9dc5c; border-color: #f9dc5c;`
                        "
                        v-if="sindx == 0"
                      >
                        {{
                          sprintStarted(sprint.sprint_start)
                            ? !sprintStarted(sprint.sprint_end)
                              ? "Текущий спринт"
                              : "Спринт просрочен"
                            : `Спринт начнётся ${getHumanDateWithTime(
                                sprint.sprint_start
                              )}`
                        }}
                      </span>

                      <span
                        :class="{
                          'sprint-card-status': true,
                          IsWaiting:
                            getSprintStatus(sprint).status == 'awaiting',
                          IsDeclined:
                            getSprintStatus(sprint).status == 'failed',
                          IsDone: getSprintStatus(sprint).status == 'done',
                        }"
                        v-if="
                          sindx != 0 && getSprintStatus(sprint).status != ''
                        "
                      >
                        {{ getSprintStatus(sprint).text }}
                      </span>
                      <div class="sprint-card-desc">
                        <span class="sprint-card-desc-text">{{
                          sprint.sprint_task_name
                        }}</span>
                        <svg
                          class="sprint-arrow-right"
                          viewBox="0 0 21 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="
                            () => {
                              sprintDetail = sprint;
                              sprintDetail.index =
                                sprintsHistory.length - sindx;
                            }
                          "
                        >
                          <path
                            d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                            fill="#C548AB"
                          />
                        </svg>
                      </div>
                    </div>

                    <div class="sprint-vertical-line-circle"></div>
                  </div>

                  <div class="sprints-vertical-line"></div>
                </div>
              </div>

              <div class="SprintAbout" v-if="sprintDetail != null">
                <div class="sprint-about-title">
                  <svg
                    class="sprint-arrow-left"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="sprintDetail = null"
                  >
                    <path
                      d="M0.292892 6.7929C-0.0976315 7.18342 -0.0976314 7.81658 0.292893 8.20711L6.65686 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84315C8.46159 1.45262 8.46159 0.819458 8.07107 0.428933C7.68054 0.038409 7.04738 0.038409 6.65685 0.428933L0.292892 6.7929ZM21 6.5L1 6.5L1 8.5L21 8.5L21 6.5Z"
                      fill="#C548AB"
                    />
                  </svg>
                  <span class="sprints-header"
                    >Спринт {{ sprintDetail.index }}</span
                  >
                </div>

                <span
                  class="sprints-about-desc"
                  style="flex-shrink: 0; display: block; margin-top: -16px; font-size: 18px;"
                  >Чтобы сдать спринт вам нужно загрузить материалы в срок</span
                >

                <div class="sprint-about-actions">
                  <div class="sprint-about-desc-info ql-snow">
                    <span class="sprints-header">Задание спринта</span>
                    <span
                      class="sprints-about-desc ql-editor"
                      v-html="sprintDetail.sprint_task_desc"
                    ></span>
                    <span
                      class="sprints-about-more-info noselect"
                      @click="showFullDesc = true"
                      >Подробнее</span
                    >
                  </div>

                  <div class="sprint-about-upload-action">
                    <span class="sprints-header">Загрузка материалов</span>

                    <template v-if="sprintStarted(sprintDetail?.sprint_start)">
                      <div
                        class="dropzone-container"
                        :style="isDragging && 'border-color: green;'"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"
                      >
                        <input
                          type="file"
                          multiple
                          name="file"
                          id="fileInput"
                          class="hidden-input"
                          @change="onChange"
                          ref="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                        />

                        <label for="fileInput" class="file-label">
                          <div class="noselect">
                            Нажмите на эту область или перетащите в нее
                            материалы
                          </div>
                        </label>
                      </div>

                      <span class="sprints-header" v-if="files.length >= 1"
                        >Вы хотите загрузить</span
                      >

                      <div
                        class="preview-container"
                        v-if="
                          files.length >= 1 ||
                            sprintDetail?.SprintMaterials.length >= 1
                        "
                      >
                        <div
                          v-for="file in files"
                          :key="file.name"
                          class="preview-card"
                        >
                          <div class="Preview-Img" :style="generateURL(file)">
                            <div
                              class="Remove-Button"
                              @click="remove(files.indexOf(file))"
                            ></div>
                          </div>
                          <span>{{ file.name }}</span>
                          <span class="UploadFiles"></span>
                        </div>

                        <div
                          v-for="file in sprintDetail?.SprintMaterials"
                          :key="file.url_file"
                          class="preview-card"
                        >
                          <div
                            class="Preview-Img"
                            :style="getStyleFromUploadedFile(file.url_file)"
                          >
                            <div
                              class="Remove-Button"
                              v-if="
                                sprintDetail?.SprintMaterials?.length > 1 &&
                                  !blockRemoveButton
                              "
                              @click="
                                () => {
                                  sprintDetail?.SprintMaterials?.length > 1
                                    ? deleteMaterial(file.id)
                                    : () => {};
                                }
                              "
                            ></div>
                          </div>
                          <span>{{ getFilenameWithExt(file.url_file) }}</span>
                          <span class="UploadFiles"></span>
                        </div>
                      </div>

                      <span
                        class="sprint-upload noselect"
                        v-if="files.length >= 1"
                        @click="uploadMaterialsInSprint"
                        >Загрузить файлы</span
                      >

                      <template
                        v-if="
                          files.length >= 1 ||
                            sprintDetail?.SprintMaterials.length >= 1
                        "
                      >
                        <span class="sprints-header">Ваш комментарий</span>
                        <textarea
                          class="sprint-user-comment"
                          v-model="sprintDetail.user_comment"
                          @change="setSprintUserComment"
                        ></textarea>
                      </template>
                    </template>

                    <span class="sprint-about-add-info-text" v-else
                      >Загрузка материалов недоступна до начала спринта</span
                    >
                  </div>

                  <div class="sprint-about-add-info">
                    <div class="sprint-about-desc-info">
                      <span class="sprints-header">Сроки сдачи спринта</span>
                      <span class="sprint-about-add-info-text"
                        >Необходимо сдать результаты работы за спринт
                        {{ sprintDetail.index }}
                        <b
                          >до
                          {{ getHumanDateWithTime(sprintDetail.sprint_end) }}</b
                        ></span
                      >
                    </div>

                    <div
                      class="sprint-about-desc-info ql-snow"
                      v-if="
                        sprintDetail.moder_comment != null &&
                          sprintDetail.moder_comment != ''
                      "
                    >
                      <span class="sprints-header"
                        >Комментарий от проверяющего</span
                      >
                      <span
                        class="sprint-about-add-info-text ql-editor"
                        v-html="sprintDetail.moder_comment"
                      ></span>
                    </div>
                  </div>
                </div>

                <div
                  class="sprint-about-history"
                  v-if="sprintDetail?.history != null"
                >
                  <span class="sprints-header">История спринта</span>
                  <div class="sprints-about-history-list">
                    <div
                      class="sprints-history-block"
                      v-for="(history, hindx) in sprintDetail.history"
                      :key="`${hindx}${history.date}`"
                    >
                      <span class="sprints-history-block-text">{{
                        history.action
                      }}</span>
                      <span class="sprints-history-block-date">{{
                        history.date
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </TwoColumnsTemplate>
      </template>
    </template>

    <el-dialog
      class="ql-snow"
      v-model="showFullDesc"
      title="Подробное описание"
      width="30%"
    >
      <span
        class="ql-editor"
        style="word-wrap: break-word;
  word-break: break-word;"
        v-html="sprintDetail?.sprint_task_desc"
      ></span>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showFullDesc = false">Закрыть</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.IsWaiting {
  color: #f9dc5c !important;
  background-color: #f9dc5c26 !important;
  border: 1px solid #f9dc5c26 !important;
}
.IsAccepted {
  color: #25ed69 !important;
  background-color: #25ed6926 !important;
  border: 1px solid #25ed6926 !important;
}
.IsDeclined {
  color: #ed254e !important;
  background-color: #ed254e26 !important;
  border: 1px solid #ed254e26 !important;
}
.IsDone {
  color: #d3caffb2 !important;
  background-color: #27235c !important;
  border: 1px solid #27235c !important;
}

.sprint-user-comment {
  position: relative;
  display: block;

  padding: 8px;

  width: 100%;
  height: fit-content;
  min-height: 130px;

  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;

  color: #ffffffe5;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}
.sprint-about-add-info-text {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}
.sprint-about-add-info {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 0px !important;

  width: 100%;
  height: auto;

  flex-basis: auto;
  flex-shrink: 1;

  background-color: transparent !important;
}

.sprint-about-add-info > * {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  flex-basis: auto;
  padding: 28px 30px;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
}

.sprint-upload {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12.5px 0%;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  color: #ffffff;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);

  border-radius: 4px;
}

.dropzone-container {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  background: transparent;
  border: 1px solid;
  border-color: #413e74;

  border-radius: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  position: relative;
  display: block;

  padding: 42px 52px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  cursor: pointer;

  color: #e8e7ec;
}
.preview-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
.preview-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: calc((100% - 30px) / 4);
  height: fit-content;
}

.preview-card > span {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  word-break: break-word;

  color: #e8e7ec;
}
.Preview-Img {
  position: relative;
  display: block;

  margin-left: auto;
  margin-right: auto;

  width: 40px;
  height: 40px;

  border-radius: 10px;
}
.Remove-Button {
  position: absolute;
  display: block;

  cursor: pointer;

  top: 0;
  right: -5px;

  width: 5px;
  height: 5px;

  background: url("./../../assets/img/profile/RemoveButtonMaterials.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#ProfilePage {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

#ProfilePage > .ProfileContent {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 23px 32px 23px 21px;
}

#ProfilePage > .ProfileContent > .ProfileBlocks {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  margin: 0%;
  padding: 0%;
}

#ProfilePage > .ProfileContent > .ProfileBlocks > .profileBlock {
  position: relative;
  height: 100%;
}

#ProfilePage > .ProfileContent > .PageHeader {
  position: relative;
  width: 100%;

  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

.BlockColumn1,
.BlockColumn2 {
  margin-right: 32px;
  flex-shrink: 0;
}
.BlockColumn1 {
  display: flex;
  flex-direction: column;
  width: 398px;
}
.BlockColumn2 {
  display: flex;
  flex-direction: column;
  width: 874px;
  overflow-y: auto !important;
}

.BlockColumn3 {
  display: flex;
  flex-direction: column;
  width: 406px;
}

.BlockColumn1 > *:not(:nth-last-child(1)) {
  margin-bottom: 32px;
}
.BlockColumn1 > *:not(:nth-last-child(1)) {
  margin-bottom: 40px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #ProfilePage .BlockColumn1 {
    height: fit-content !important;
  }

  #ProfilePage .BlockColumn2 {
    margin-bottom: 70px !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 100% !important;
    margin-bottom: 0px !important;
  }
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }
}

@media (max-width: 992px) {
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 21px 0% 21px;

    row-gap: 20px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks .profileBlock {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    padding: 0%;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
    margin-bottom: 20px;
  }
  .BlockColumn2 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    order: 3;
  }
  .BlockColumn3 {
    width: 100%;
    max-width: 100%;

    height: auto !important;
    max-height: 400px;

    padding-bottom: 21px;
    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    display: none;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    width: 47%;
    max-width: 47%;

    height: fit-content !important;

    order: 2;
  }
  .BlockColumn3 {
    width: 47%;
    max-width: 47%;

    height: auto !important;

    padding-bottom: 21px;
    order: 3;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 350px;
    width: 47% !important;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 47% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    width: 57%;
    max-width: 57%;

    height: fit-content !important;

    order: 2;
  }
  .BlockColumn3 {
    width: 37%;
    max-width: 37%;

    height: auto !important;

    padding-bottom: 21px;
    order: 3;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 350px;
    width: 57% !important;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 37% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 32px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 25%;
    max-width: 398px;
  }
  .BlockColumn2 {
    width: 50%;
    max-width: 874px;
  }
  .BlockColumn3 {
    width: 25%;
    max-width: 406px;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex: auto;
    overflow-y: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    justify-content: center;
  }
}
</style>

<style scoped>
.SprintsContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 20px;

  width: 100%;
  height: 100%;

  flex-shrink: 1;
  flex-grow: 0;
}
.sprints-header {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sprints-list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding-left: 22px;

  width: 100%;
  height: fit-content;
}
.sprint-block {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;

  z-index: 2;
}
.sprint-block-title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sprint-block-title-bold {
  font-weight: 700;
}

.sprints-vertical-line {
  position: absolute;
  display: block;

  padding-top: 5px;

  left: 5px;
  bottom: 0;

  width: 1px;
  height: calc(100% - 4px);

  background-color: #514ebe;

  z-index: 1;
}

.sprint-block-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: #1e1c42;
}

.sprint-vertical-line-circle {
  position: absolute;
  display: block;

  top: 3px;
  left: -22px;
  width: 11px;
  height: 11px;

  background: white;
  border-radius: 100%;
}

.sprint-card-desc > * {
  margin-top: auto;
  margin-bottom: auto;
}
.sprint-card-desc {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  height: fit-content;
}

.sprint-card-desc-text {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  width: fit-content;
  max-width: 100%;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */

  flex-shrink: 1;
  flex-grow: 0;

  overflow: hidden;
}
.sprint-arrow-right {
  position: relative;
  width: 21px;
  height: auto;

  cursor: pointer;

  aspect-ratio: 1 / 0.7619;
}

.sprint-arrow-left {
  position: relative;
  width: 21px;
  height: auto;

  cursor: pointer;

  aspect-ratio: 1 / 0.7142;
}

.sprint-card-status {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  color: #1dd185;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid #1dd185;
}

.SprintContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 28px;

  width: 100%;
  height: 100%;
}

.SprintAbout {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 100%;
  height: auto;

  overflow-y: auto;
}

.sprint-about-title > * {
  margin-top: auto;
  margin-bottom: auto;
}
.sprint-about-title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  width: fit-content;
  height: fit-content;

  margin-top: 6px;
}

.sprint-about-history {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: auto;
}

.sprints-about-history-list {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  width: 100%;
  height: auto;

  padding-bottom: 40px;
}

.sprints-history-block {
  position: relative;
  display: flex;

  padding: 20px 30px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  width: 100%;
  height: auto;

  border-radius: 20px;
  background-color: #1e1c42;
}

.sprints-history-block-text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.sprints-history-block-date {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sprint-about-actions > * {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  flex-basis: auto;
  padding: 28px 30px;

  width: 440px;
  height: auto;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
}
.sprint-about-actions {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: fit-content;
}

.sprints-about-more-info {
  position: relative;
  display: block;

  cursor: pointer;
  margin-top: auto;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-bottom: 1px solid #433e66;
}

.sprints-about-desc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  width: auto;
  height: fit-content;

  color: #c8c3de;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}
</style>

<script>
import { getPinnedTask, getUserCells, findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";

import { create, remove, getUserSettings } from "@/api/setting.js";

import { getAllSteps } from "@/api/stage_step.js";

import { getLastQueryByUser } from "@/api/job_candidate_request.js";

import { getAllDifficulties, getUserPinnedTask } from "@/api/job.js";

import {
  getUserSprints,
  deleteSprintMaterial,
  uploadSprintMaterials,
  setSprintUserComment,
} from "@/api/sprints.js";

import TasksList from "@/components/Profile/TasksList.vue";
import NewsFeed from "@/components/Profile/NewsFeed.vue";
import Sidebar from "@/components/Profile/Sidebar.vue";
import Competence from "@/components/Profile/CompetenceBlock.vue";
import ProfileProjectCard from "@/components/Profile/BetaProfileProjectCard.vue";
import ProfileProgressCard from "@/components/Profile/BetaProfileProgressCard.vue";
import TipBlock from "@/components/CustomElements/TipBlock.vue";
import OctiJobRecs from "@/components/Profile/OctiJobRecs.vue";

import TwoColumnsTemplate from "@/components/ProfileTemplates/TwoColumns.vue";

// import Stage from "@/components/Profile/StagesCompetence.vue";

import EducationScreen from "@/views/ProfileEducation/Education.vue";

export default {
  data() {
    return {
      // isModalVisible: true,

      education_show: false,
      education_show_component: "",

      Difficulties: [],

      userCells: null,
      userStepsReady: [],
      PinnedTask: {},

      sprintsHistory: [],
      sprintDetail: null,

      isDragging: false,
      files: [],

      UserLastQuery: null,

      blockRemoveButton: false,
      load: true,

      showFullDesc: false,
    };
  },
  name: "BetaHome",
  components: {
    Sidebar,
    Competence,
    ProfileProjectCard,
    ProfileProgressCard,
    NewsFeed,
    TasksList,
    // Stage,
    EducationScreen,
    TipBlock,
    OctiJobRecs,
    TwoColumnsTemplate,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    UserCells() {
      return this.userCells == null
        ? []
        : this.userCells.user_cells.map((x) => x.level_id);
    },
    getTaskCompBlockReq() {
      if (this?.PinnedTask?.UserPinTask?.[0] == null) {
        return [];
      }

      let blockReq =
        this.getTaskDifficult(this?.PinnedTask?.UserPinTask?.[0]?.level) * 3;
      let res = [];
      res.push([]);
      for (let i = 0; i < blockReq; i++) {
        res[0][i] = true;
      }
      return res;
    },
    sprintsHistoryExist() {
      return this.sprintsHistory != null && this.sprintsHistory?.length != 0;
    },
    sprintJobIsEnded() {
      if (!this.sprintsHistoryExist) {
        return false;
      }

      return this.sprintsHistory?.[0]?.job_is_done;
    },

    userHasJobRequest() {
      return false;
    },
    jobRequestSended() {
      if (this.UserLastQuery == null) {
        return false;
      }

      if (this.UserLastQuery?.["status"] === "Ожидает рассмотрения") {
        return true;
      }

      return false;
    },
    jobRequestDeclined() {
      if (this.UserLastQuery == null) {
        return { res: false, reason: "" };
      }

      if (this.UserLastQuery?.["status"] === "Отклонена") {
        return { res: true, reason: this.UserLastQuery?.comment };
      }

      if (this.UserLastQuery?.["status"] === "Отменена пользователем") {
        return { res: true, reason: "user" };
      }

      return { res: false, reason: "" };
    },
    isFreeJob() {
      return (
        this?.PinnedTask?.UserPinTask?.[0]?.JobWorkersList.length == 0 ||
        this?.PinnedTask?.UserPinTask?.[0]?.repeatable == true
      );
    },
    isNewReqStatus() {
      if (this.UserLastQuery == null) {
        return false;
      }

      return this.UserLastQuery?.new;
    },
    userIsWorker() {
      if (
        this?.PinnedTask?.UserPinTask?.[0]?.JobWorkersList == null ||
        this?.PinnedTask?.UserPinTask?.[0]?.JobWorkersList.length == 0
      ) {
        return false;
      }

      let JobWorkersFiltered = this?.PinnedTask?.UserPinTask?.[0]?.JobWorkersList.filter(
        (x) => x.id === this?.user?.id
      );

      return JobWorkersFiltered?.length === 1;
    },
    jobIsRepeateable() {
      return this?.PinnedTask?.UserPinTask?.[0]?.repeatable;
    },
    isArchieveJob() {
      return this?.PinnedTask?.UserPinTask?.[0]?.archieve;
    },

    enoughCompetenceForJob() {
      if (
        !this?.PinnedTask?.UserPinTask?.[0]?.levels ||
        this?.PinnedTask?.UserPinTask?.[0]?.levels.length === 0
      ) {
        return false;
      }

      let projectLevels = this?.PinnedTask?.UserPinTask?.[0].levels.map(
        (level) => level.id
      );

      let containsAllValues = projectLevels.every((level) =>
        this.userLevels.includes(level)
      );

      return containsAllValues;
    },
    userLevels() {
      if (
        this?.user?.user_cells == null ||
        this?.user?.user_cells?.length === 0
      ) {
        return [];
      }

      return this.user.user_cells.length > 0
        ? this.user.user_cells.map((cell) => cell.level_id)
        : [];
    },
    tipContent() {
      if (this.isArchieveJob) {
        return "Выбранная задача является архивной и не может быть выполнена после получения компетенций.";
      }

      if (this.jobRequestSended) {
        return "Компетенции получены! Заявка на выполнение задачи ожидает рассмотрения";
      }

      if (
        this.jobRequestDeclined.res == true &&
        this.jobRequestDeclined.reason != "" &&
        this.jobRequestDeclined.reason != "user" &&
        this.isNewReqStatus
      ) {
        return `Компетенции получены! Заявка на выполнение задачи отклонена<br/><br/>
        ${this.jobRequestDeclined.reason}
        `;
      }

      if (this.sprintJobIsEnded) {
        if (
          this.sprintsHistory?.[0]?.moder_comment ===
          "Не выполнил спринт, не загрузил материалы"
        ) {
          return `Вы были исключены из задачи за невыполнение работ<br/>К сожалению, мы вынуждены отстранить вас от решения задачи по причине невыполнения задачи в срок`;
        } else {
          return `Поздравляем! Вы успешно завершили задачу. Можете выбрать новую задачу для выполнения`;
        }
      }

      if (!this.jobIsRepeateable && !this.isFreeJob) {
        return "<b>Компетенции получены!</b> К сожалению, твой коллега опередил тебя и приступил к задаче раньше. Но не расстраивайся, я подобрал для тебя подборку задач, которые подойдут тебе с твоими компетенциями :) <br/><br/> Изучи мою подборку, выбери задачу которая тебе понравилась, закрепи ее и следуй дальнейшим указаниям!";
      } else {
        return "<b>Компетенции получены!</b> После того, как все необходимые навыки были изучены, тебе необходимо нажать кнопку приступить к задаче и следовать дальнейшим указаниям. <br /> <br /> P.S. Кнопка <b>приступить к задаче</b> уже появилась в закрепленной задаче, скорее нажимай!";
      }
    },

    showOctiRecs() {
      //Если заявку отклонили и задача не типовая
      let a =
        this.enoughCompetenceForJob &&
        !this.jobIsRepeateable &&
        this.jobRequestDeclined.res == true &&
        this.isNewReqStatus;

      //Если заявку отклонили и задача типовая
      let b =
        this.enoughCompetenceForJob &&
        this.jobIsRepeateable &&
        this.jobRequestDeclined.res == true &&
        this.isNewReqStatus;

      //Если заявку отклонили и задача не типовая
      let c =
        this.enoughCompetenceForJob &&
        !this.jobIsRepeateable &&
        !this.isFreeJob;

      //Если задача завершена
      let d = this.sprintJobIsEnded;

      return a || b || c || d;
    },
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },

  async created() {
    if (this.user == null) {
      this.updateUser();
    } else {
      this.getUser(this.user.id);
    }

    getAllDifficulties().then((response) => {
      this.Difficulties = response.data;
    });

    getUserCells(this.user.id).then((response) => {
      this.userCells = response.data;
    });
    await this.initialization();

    // getUserSettings(this.user.id).then((res) => {
    //   if (res.data.settings && res.data.settings.length > 0) {
    //     if (res.data.settings.map((setting) => setting.id).includes(1))
    //       this.education_show = false;
    //   } else {
    //     this.education_show = true;
    //   }
    // });
    this.load = false;
    // getAllSteps().then((res) => {
    //   let Steps = res.data;

    //   this.stagesList = [];
    //   this.stepsList = Steps;

    //   for (let Step of Steps) {
    //     let stageExist = this.stagesList.find((obj) => {
    //       return obj.id === Step.stage.id;
    //     });

    //     if (stageExist == null) {
    //       this.stagesList.push(Step.stage);
    //     }
    //   }
    // });

    // findAllUserSteps(this.user.id).then((res) => {
    //   this.userStepsReady = res.data.StageStepUsers;
    // });
  },
  mounted() {},
  methods: {
    getSectionsArray(arr) {
      return arr.map((x) => x.id);
    },
    getFirstSection(arr) {
      return arr[0];
    },

    async initialization() {
      let lastQuery = await getLastQueryByUser(this.user.id);
      this.UserLastQuery = lastQuery.data;

      let _pinned_task_req = await getUserPinnedTask(this.user.id);
      this.PinnedTask = _pinned_task_req.data;

      let sprints_req = await getUserSprints(
        this.user.id,
        this.PinnedTask?.["UserPinTask"]?.[0]?.id
      );

      this.sprintsHistory = this.getSprintsSorted(sprints_req.data);

      if (this.sprintDetail?.id != null) {
        let indexInArray = this.sprintsHistory.findIndex(
          (x) => x.id === this.sprintDetail?.id
        );

        this.sprintDetail = this.sprintsHistory[indexInArray];
        this.sprintDetail.index = this.sprintsHistory.length - indexInArray;

        this.files = [];
      }

      this.blockRemoveButton = false;
    },

    async setSprintUserComment() {
      await setSprintUserComment(this.sprintDetail.id, {
        user_comment: this.sprintDetail.user_comment,
      });

      await this.initialization();
    },

    getSprintStatus(sprint) {
      if (
        sprint?.status == "Не проверено" &&
        sprint?.SprintMaterials?.length == 0
      ) {
        return { status: "failed", text: "Спринт не выполнен" };
      }

      if (
        sprint?.status == "Не проверено" &&
        sprint?.SprintMaterials?.length > 0
      ) {
        return { status: "awaiting", text: "Ожидает проверки" };
      }

      if (
        sprint?.status != "Не проверено" &&
        sprint?.SprintMaterials?.length > 0
      ) {
        return { status: "done", text: "Проверено" };
      }

      return { status: "", text: "" };
    },

    getSprintsSorted(Sprints) {
      if (Sprints == null) {
        return [];
      }

      return Sprints.sort((a, b) => b.id - a.id);
    },

    async deleteMaterial(mat_id) {
      this.blockRemoveButton = true;

      await deleteSprintMaterial(mat_id);

      await this.initialization();
    },

    async uploadMaterialsInSprint() {
      let formData = new FormData();

      this.files.forEach((photo) => {
        if (photo) {
          formData.append(photo.name, photo);
        }
      });

      let a = await uploadSprintMaterials(this.sprintDetail.id, formData);

      await this.initialization();
    },

    ...mapMutations("auth", ["SET_USER"]),
    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    sprintStarted(date_start) {
      if (date_start == null) {
        return false;
      }

      const specifiedDate = new Date(date_start);
      const currentDate = new Date();

      return specifiedDate <= currentDate;
    },

    isEnoughCompetence() {
      let user_competence =
        this.user.user_cells.length > 0
          ? user.user_cells.map((cell) => cell.level_id)
          : [];
    },

    getTaskDifficult(DiffName) {
      let indx = this.Difficulties.findIndex((obj) => {
        return obj.name === DiffName;
      });
      return indx + 1;
    },
    getTaskCompBlockReq() {
      let blockReq =
        this.getTaskDifficult(this.PinnedTask?.["UserPinTask"]?.[0].level) * 3;
      let res = [];
      res.push([]);
      for (let i = 0; i < blockReq; i++) {
        res[0][i] = true;
      }
      return res;
    },

    educationFocusComponent(ComponentName) {
      this.education_show_component = ComponentName;
    },

    getHumanDate(date_start) {
      if (date_start == null || date_start == "") {
        return "";
      }

      let Date = date_start.split("T")[0].split("-");
      return `${Date[2]}.${Date[1]}.${Date[0]}`;
    },
    getHumanDateWithTime(date_end) {
      if (date_end == null || date_end == "") {
        return "";
      }

      let Date = date_end.split("T")[0].split("-");
      let Time = date_end.split("T")[1].split(":");
      return `${Date[2]}.${Date[1]}.${Date[0]} ${Time[0]}:${Time[1]}`;
    },

    setFilesList(SprintsList) {
      this.files = SprintsList.map((x) => {
        // Пример адреса файла
        const filePath = x.url_file;

        // Функция для извлечения имени файла с расширением без чисел до символа "-"
        let getCleanFileNameWithExtension = (filePath) => {
          const parts = filePath.split(/[\\\/]/);
          const fileNameWithExtension = parts[parts.length - 1];
          const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
            /^\d+-/,
            ""
          );
          return fileNameWithoutNumbersBeforeDash;
        };

        const cleanFileNameWithExtension = getCleanFileNameWithExtension(
          filePath
        );

        const fileBlob = new Blob([cleanFileNameWithExtension], {
          type: "text/plain",
        });

        return new File([fileBlob], cleanFileNameWithExtension, {
          type: "text/plain",
        });
      });
    },
    getFilenameWithExt(filePath) {
      let getCleanFileNameWithExtension = (filePath) => {
        const parts = filePath.split(/[\\\/]/);
        const fileNameWithExtension = parts[parts.length - 1];
        const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
          /^\d+-/,
          ""
        );
        return fileNameWithoutNumbersBeforeDash;
      };

      return getCleanFileNameWithExtension(filePath);
    },

    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
        incomingFiles.some(
          (file) => file.name === r.name && file.size === r.size
        )
      );

      console.log(this.$refs.file.files);

      if (fileExist) {
        self.showMessage = true;
        alert("Данные файлы уже загружены.");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      let style = `
      background: url('${fileSrc}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },
    getStyleFromUploadedFile(mat_url) {
      let style = `
      background: url('${mat_url}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },
  },
};
</script>
